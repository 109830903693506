<template>
  <div class="login">
    <div class="content">
      <div class="title">
        <img src="../../assets/title.png" class="titleImg" alt="">
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="userInfo.phone"
          name="phone"
          placeholder="请输入账号"
          :rules="[{ required: true, message: '请输入账号' }]"
          type="text"
        >
        <template #left-icon>
          <img src="@/assets/zhanghao.png" class="img30" alt="">
        </template>
        </van-field>
        <van-field
          v-model="userInfo.pwd"
          :type="password"
          name="pwd"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请输入密码' }]"
        >
        <template #left-icon>
          <img src="@/assets/mima.png" class="img30" alt="">
        </template>
        <template #right-icon>
          <van-icon name="eye-o" v-if="eyeShow == true " @click="eyeOpen" />
          <van-icon name="closed-eye" v-if="eyeShow == false " @click="eyeOpen" />
        </template>
        </van-field>
        <div style="margin: 16px;margin-top:50px;">
          <van-button round block type="info" color="#2163E9" native-type="submit"
            >确认登录</van-button
          >
        </div>
        <div class="registered" @click="linkRegistered">
          <span class="border_btm">如何获取登录账号？</span>
        </div>
      </van-form>
    </div>
    <div class="link">
        <img src="@/assets/bg.png" class="bgimg" alt="">
      </div>
  </div>
</template>

<script>
import { getLocal,removeLocal,setLocal } from '@/utils/local.js'
import parentLogin from '@/api/login.js'
export default {
  data () {
    return {
        userInfo:{
            phone: '',
            pwd: '',
        },
        eyeShow:false,
        password:'password'
    }
  },
  created () {
    if(getLocal('phone')) {
      this.userInfo.phone = getLocal('phone')
    }
    if(getLocal('pwd')) {
      this.userInfo.pwd = getLocal('pwd')
    }
  },
  methods :{
      async onSubmit (values) {
        const obj = {
          student_name: values.phone ,
          number_id : values.pwd
        }
        const res = await parentLogin(obj)
          if(res.data.code == 200) {
            setLocal('phone',obj.student_name)
            setLocal('pwd',obj.number_id)
            //  请求成功后跳转 移除账号
            this.$toast.success('登录成功！')
            setLocal('token',res.data.data.token)
            setTimeout(() => {
              this.$router.push({path:'/home',query:{student_id:res.data.data.student_id}})
            }, 500);
          }
      },
      linkRegistered () {
        this.$router.push({path:'/registered'})
      },
      eyeOpen () {
        this.eyeShow == true ? this.eyeShow = false : this.eyeShow = true
        this.eyeShow == true ? this.password = 'text' : this.password = 'password'
      }
  }
}
</script>

<style lang="less" scoped>
    .login {
      height: 100vh;
      padding: 100px 0;
      background: linear-gradient(180deg, #FCFDFF 0%, #C3E0FE 100%);
        .content {
          padding-left: 70px;
          padding-right: 70px;
          position: relative;
          .title {
            width: 100%;
            margin-bottom: 20px;
            .titleImg {
              width: 100%;

            }
          }
          .catimg{
            width: 20px;
            height: 20px;
          }
            ::v-deep .van-field {
                padding: 30px 16px 10px;
                border-bottom: 1px solid #4D4D4D;
                display: flex;
                align-items: center;
            }
            .footer {
              font-size:14px;
              text-align:center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -200px;
              padding-bottom: 25px;
            }
            .registered {
              font-size: 14px;
              text-align: right;
              color: #2163E9;
              .border_btm {
                border-bottom: 1px solid #2163E9
              }
            }
        }
        .link {
              margin-top: 20px;
              font-size:14px;
              text-align:center;
              .bgimg {
                width: 100%;
              }
        }
        .van-cell  {
          background-color: transparent;
        }
        .van-field__control::placeholder {
          color: #000 !important;
        }
        ::v-deep .van-field__control  {
          color: #000 !important;
        }
        .img30{
          width: 15px;
          height: 15px;
        }
    }
</style>
