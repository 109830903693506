
// const res = {
//     code:200,
//     data:{
//         phone:'1368881368888',
//         pwd:'123456'
//     },
//     message:'操作成功！'
// }

import _fetch from "../utils/request"

export default function parentLogin(data) {
    return _fetch({
        url:'/cs/login ',
        method:'post',
        data
    })
}